import { Await, redirect } from '@remix-run/react';
import { type AxiosError } from 'axios';

import { isErrorCausedByResponse, SiteBaseURL } from '../services/public';
import { getToken } from './getToken';
import { isServer } from './isServer';
import { paramsWithRedirectTo } from './redirect-to';

// Copied from react-router, modified to add generics
interface AwaitResolveRenderFunction<T> {
  (data: Awaited<T>): React.ReactNode;
}

// Copied from react-router, modified to add generics
interface AwaitProps<T extends Promise<unknown>> {
  children: AwaitResolveRenderFunction<T>;
  errorElement?: React.ReactNode;
  resolve: T;
}

/**
 * This wrapper works around the lack of typing for `Await` + `defer` in
 * react-router. It _only_ accepts a render-prop function as a child because the
 * `useAsyncValue` API is... impossible to make type-safe.
 */
export function AwaitTypedDeferred<T extends Promise<unknown>>({
  children,
  errorElement,
  resolve,
}: AwaitProps<T>) {
  return (
    <Await resolve={resolve} errorElement={errorElement}>
      {children}
    </Await>
  );
}

/**
 * Create a venue URL + featured-game.
 */
export function venueUrlFrom(
  currentUrl: string,
  venueId: string,
  gamePackId: string | undefined
) {
  // Use the current url as the base since an absolute URL is required for the
  // URL ctor.
  const url = new URL(currentUrl);
  url.pathname = `/venue/${venueId}`;
  if (gamePackId) url.searchParams.append('featured-game', gamePackId);
  return url;
}

/**
 *
 * @param redirectTo Note: if within a clientLoader, do not rely on
 * window.location.href being updated! It may not have been yet, since the
 * loader is still processing. Use `action.request.url`.
 *
 * Throw this within a loader to redirect.
 */
export function redirectToRegister(redirectTo: string) {
  const search = paramsWithRedirectTo(redirectTo);

  if (isServer()) {
    return redirect(`/register?${search.toString()}`);
  }

  const url = new SiteBaseURL();
  url.pathname = '/register';
  url.search = search.toString();
  return redirect(url.toString());
}

/**
 *
 * @param redirectTo Note: if within a loader, do not rely on
 * window.location.href being updated! It may not have been yet, since the
 * loader is still processing. Use `action.request.url`.
 *
 * Throw this within a loader to redirect.
 */
export function redirectToLogin(redirectTo: string) {
  const search = paramsWithRedirectTo(redirectTo);
  const url = new SiteBaseURL();
  url.pathname = '/login';
  url.search = search.toString();
  return redirect(url.toString());
}

/**
 * best-effort "slugify" into something readable and URL-safe
 */
export function slugify(name: string) {
  return encodeURIComponent(
    name
      .replace(/\s+/gi, '-')
      .replace(/[^a-z0-9-]/gi, '')
      .toLowerCase()
  );
}

export function isUnauthenticated(e: unknown): boolean {
  return (
    // Axios
    // fetch helpers
    (isAxiosError(e) && e.response?.status === 401) ||
    // Response/Fetch
    (e instanceof Response && e.status === 401) ||
    (isErrorCausedByResponse(e) && e.cause.status === 401)
  );
}

/**
 * Somewhat copied from axios to avoid needing to actually import the
 * un-tree-shakeable and quite-large axios. This is used in some scenarios to
 * determine unauthenticated state, and it felt a shame to duplicate the logic.
 */
function isAxiosError(payload: unknown): payload is AxiosError<unknown> {
  return (
    typeof payload === 'object' &&
    payload !== null &&
    'isAxiosError' in payload &&
    payload.isAxiosError === true
  );
}

/**
 * In a loader context, complex automatic redirect rules do not make sense. All
 * we care about is whether the user should be redirected to /login or /register
 * after an auth failure. Deeper in the app can handle whether the token should
 * be deleted, renewed, user activation, etc.
 * @throws any response error is that is not an unauthenticated (401) error, or
 * any other error from the executor
 * @param requestExecutor
 * @param redirectTo The unencoded URL after login/register.
 * @param opts pass `admin: true` if the loader is used in an admin route. this will
 * prefer redirecting to /login instead of /register.
 * @returns
 */
export async function tokenWithRedirect<T>(
  requestExecutor: (token: string) => T,
  redirectTo: string,
  opts?: { admin?: boolean }
) {
  const isAdmin = opts?.admin ?? false;
  const token = getToken();
  try {
    return await requestExecutor(token ?? '');
  } catch (err) {
    // We don't know what this is, just rethrow it.
    if (!isUnauthenticated(err)) throw err;

    // If token was present, but somehow the request still failed, they likely
    // have a user account and expired token. Redirect to login.
    if (token || isAdmin) throw redirectToLogin(redirectTo);
    else throw redirectToRegister(redirectTo);
  }
}
